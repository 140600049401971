<template>
  <div>
    <div
      variant="light"
      class="justify-content-end container alert alert-white alert-shadow"
      style="padding-top: 10px"
    >
      <div class="row">
        <b-col md="3">
          <b-container fluid>
            <b-col><label>Chọn ngân hàng:</label></b-col>
            <b-col>
              <b-form-select
                v-model="searchData.bankCode"
                @change="selectBank()"
              >
                <option selected disabled>Chọn Ngân hàng</option>
                <option
                  v-for="(item, bankCode) in listBank"
                  :value="bankCode"
                  :key="bankCode"
                >
                  {{ item }}
                </option>
              </b-form-select>
            </b-col>
          </b-container>
        </b-col>
        <b-col md="3">
          <b-container fluid>
            <b-col><label :for="`startDate`">Ngày bắt đầu :</label></b-col>
            <b-col>
              <b-form-input
                :id="`type-date`"
                :type="`date`"
                v-model="searchData.startDate"
              ></b-form-input>
            </b-col>
          </b-container>
        </b-col>
        <b-col md="3">
          <b-container fluid>
            <b-col><label :for="`endDate`">Ngày kết thúc :</label></b-col>
            <b-col>
              <b-form-input
                :id="`type-date`"
                :type="`date`"
                v-model="searchData.endDate"
              ></b-form-input>
            </b-col>
          </b-container>
        </b-col>
        <b-col md="3">
          <b-container fluid>
            <b-col><label>Loại giao dịch:</label></b-col>
            <b-col>
              <b-form-select v-model="searchData.trans_action">
                <option value="ALL">Tất cả</option>
                <option value="DEPOSIT">Nạp tiền</option>
                <option value="WITH_DRAW">Rút tiền</option>
              </b-form-select>
            </b-col>
          </b-container>
        </b-col>
        <b-col md="3">
          <b-container fluid>
            <b-col><label>Mã GD 9Pay</label></b-col>
            <b-col>
              <b-form-input v-model="searchData.payment_id"></b-form-input>
            </b-col>
          </b-container>
        </b-col>
        <b-col md="3">
          <b-container fluid>
            <b-col><label>Mã GD ngân hàng</label></b-col>
            <b-col>
              <b-form-input v-model="searchData.bank_payment_id"></b-form-input>
            </b-col>
          </b-container>
        </b-col>
      </div>
      <div class="row col-md-12 justify-content-end">
        <button
          ref="submit-search"
          class="
            btn btn-elevate btn-success
            font-weight-bold
            px-11
            py-2
            my-4
            font-size-3
            mr-3
          "
          v-b-modal.export-desc
          @click="$bvModal.show('export-desc')"
        >
          <i class="fa fa-download"></i>
          Export
        </button>
        <b-modal id="export-desc" title="Yêu cầu xuất dữ liệu">
          <div class="row">
            <div class="col-6">
              <b>Chọn ngân hàng: </b>{{ this.searchData.bankCode }}
            </div>
            <div class="col-6">
              <b>Loại giao dịch: </b>{{ this.searchData.trans_action }}
            </div>
            <div class="col-6">
              <b>Ngày bắt đầu: </b>{{ this.searchData.startDate }}
            </div>
            <div class="col-6">
              <b>Ngày kết thúc: </b>{{ this.searchData.endDate }}
            </div>
            <div class="col-6">
              <b>Mã GD 9Pay: </b>{{ this.searchData.payment_id }}
            </div>
            <div class="col-6">
              <b>Mã GD ngân hàng: </b>{{ this.searchData.bank_payment_id }}
            </div>
          </div>
          <br />
          <div class="d-block text-center">
            <b-form-textarea
              placeholder="Nhập mô tả yêu cầu xuất dữ liệu ..."
              v-model="exportDesc"
              rows="3"
            ></b-form-textarea>
          </div>
          <template #modal-footer="{ ok, cancel }">
            <b-button size="sm" variant="danger" @click="cancel()">
              Cancel
            </b-button>
            <b-button size="sm" variant="success" @click="exportTransDetail">
              Export
            </b-button>
          </template>
        </b-modal>
        <button
          ref="submit-search"
          class="
            btn btn-elevate btn-primary
            font-weight-bold
            px-11
            py-2
            my-4
            font-size-3
          "
          @click="searchTransDetail()"
        >
          Search
        </button>
      </div>
    </div>

    <b-card-text v-if="errorMessage" align="center" class="text-danger">{{
      errorMessage
    }}</b-card-text>
    <div id="detail" class="row">
      <div class="col-md-12">
        <b-card-group deck>
          <b-card header-tag="header" footer-tag="footer">
            <template v-slot:header>
              <h6 class="mb-0">Danh sách chi tiết</h6>
            </template>
            <b-table
              ref="table"
              striped
              hover
              responsive
              caption-top
              :busy="transDetail.tableLoading"
              :items="transDetail.items"
              :fields="transDetail.fields"
              show-empty
            >
              <template #table-busy>
                <div class="text-center text-danger my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong>...</strong>
                </div>
              </template>
              <template #empty="scope"> </template>
              <template #cell(detail)="data">
                <a target="_blank" :href="`${data.value}`">Xem</a>
              </template>
              <template #cell(payment_id)="data">
                <a target="_blank" :href="'#/transactions/detail?transaction_id='+data.item.trans_id">{{data.item.payment_id}}</a>
              </template>
            </b-table>
            <div class="mt-3">
              <b-pagination
                v-model="currentPage"
                :total-rows="transDetail.paginate.total"
                :per-page="transDetail.paginate.limit"
                first-text="First"
                prev-text="Prev"
                next-text="Next"
                last-text="Last"
                align="right"
              ></b-pagination>
            </div>
          </b-card>
        </b-card-group>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Common from "@/core/mixins/common";
import RepositoryFactory from "@/core/repositories/repositoryFactory";
import Message from "@/core/config/message.config";

const ReconcileRepository = RepositoryFactory.get("reconcile");
const BankRepository = RepositoryFactory.get("bank");

export default {
  name: "TransactionDetail",
  mixins: [Common],
  components: {},
  data() {
    return {
      listBank: {
        CTG: "VietinBank",
        VPB: "VPBank",
        BIDV: "BIDV",
        MBB: "MB BANK",
        NAPAS: "NAPAS",
        STBBANK: "Sacombank",
        TCB: "Techcombank",
        TQT: "Thẻ quốc tế",
        NAPAS_PAYGATE: "NAPAS Merchant Hosted"
      },
      searchData: {
        bankCode: "",
        startDate: this.getYesterday(),
        endDate: this.getCurrentDay(),
        trans_action: "ALL",
        payment_id: "",
        bank_payment_id: "",
      },
      exportDesc: "",
      currentPage: 1,
      transDetail: {
        items: [],
        tableLoading: false,
        currentPage: 1,
        paginate: {
          limit: 25,
          total: 0,
        },
      },
      errorMessage: "Vui lòng chọn thông tin ngân hàng",
      cmsUrl: process.env.VUE_APP_API_CMS_V1_URL
    };
  },
  methods: {
    selectBank() {
      if (this.bankCode != "") {
        this.errorMessage = null;
      }
    },
    async searchTransDetail() {
      try {
        if (this.errorMessage != null) {
          return;
        }
        this.$bus.$emit("show-loading", true);
        const params = {
          from_date: this.searchData.startDate,
          to_date: this.searchData.endDate,
          bank_code: this.searchData.bankCode,
          trans_action: this.searchData.trans_action
        };
        if (this.searchData.payment_id.length > 0) {
          params.payment_id = this.searchData.payment_id;
        }
        if (this.searchData.bank_payment_id.length > 0) {
          params.bank_payment_id = this.searchData.bank_payment_id;
        }
        params.page = this.currentPage;
        params.limit = this.transDetail.limit;
        const response = await BankRepository.getListTransactionByPaymentMethod(
          params
        );
        this.$bus.$emit("show-loading", false);
        const body = response.data;
        if (body.error_code === 0) {
          this.transDetail.items = body.data.data.data;
          this.transDetail.currentPage = body.data.data.currentPage;
          this.transDetail.paginate.total = body.data.data.total;
          this.transDetail.paginate.limit = body.data.data.per_page;
          body.data.data.data.forEach((value) => {
            value["detail"] =
              this.cmsUrl + "/admin/transaction/view/" + value.payment_id;
          });
        }
      } catch (e) {
        this.$bus.$emit("show-loading", false);
        return this.showError(Message.ERROR.SERVER);
      }
    },

    async exportTransDetail() {
      try {
        if (this.errorMessage != null) {
          return;
        }
        this.$bvModal.hide("export-desc");
        this.$bus.$emit("show-loading", true);
        const params = {
          from_date: this.searchData.startDate,
          to_date: this.searchData.endDate,
          bank_code: this.searchData.bankCode,
          trans_action: this.searchData.trans_action,
          export_desc: this.exportDesc,
        };
        const response = await ReconcileRepository.exportDataDetail(params);
        this.$bus.$emit("show-loading", false);
        const body = response.data;
        if (body.error_code === 0) {
          return this.showSuccess("Dữ liệu đang được xử lý.");
        }

        return this.showError(Message.ERROR.UPLOAD_FILE);
      } catch (e) {
        this.$bus.$emit("show-loading", false);
        return this.showError(Message.ERROR.SERVER);
      }
    },

    getTransactionDetail() {},

    convertStatus(status) {
      switch (status) {
        case 0:
          return "PENDING";
        case 1:
          return "SUCCESS";
        case -1:
          return "FAIL";
      }
    },
  },
  computed: {
    ...mapGetters(["layoutConfig"]),

    config() {
      return this.layoutConfig();
    }
  },
  created() {},
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Chi tiết giao dịch" }]);
  },
  watch: {
    $route: "searchTransDetail",
    currentPage() {
      this.searchTransDetail();
    },
  },
};
</script>
